import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

/* Assets */
import Logo from '../../Assets/logo.svg';
import { IconContext } from 'react-icons/lib';

/* Icons */
import * as FiIcons from "react-icons/fi";

/* Components */
import { SideBarData } from './SideBarData';
import SubMenu from './SubMenu';

/* Bootstrap */
import Button from 'react-bootstrap/Button';

const SidebarNav = styled.nav`
    //border-right: 1px solid #dfdfdf;
    width: 100%;
    height: 100%;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index; 10;
`;

const SidebarWrap = styled.div`
    width: 100%;
    margin-top: 40px;
`;

const SideBar = ({ setShowMini }) => {

    return (
        <IconContext.Provider value={{ }}>
            <div className='LeftSide'>
                <SidebarNav>
                <img src={Logo} alt="logotipo" className='LogotipoSide'/>
                    <SidebarWrap>
                        {SideBarData.map((item, index) => {
                            return <SubMenu item={item} key={index} />
                        })}
                    </SidebarWrap>
                    <div className='BtnToggle'>
                        <Button
                            variant="success"
                            size='sm'
                            onClick={() => {
                                setShowMini(true);
                            }}
                        >
                            <FiIcons.FiChevronLeft />
                        </Button>
                    </div>
                    <div className='Logout d-grid'>
                      <Button
                        variant="outline-secondary"
                        size='sm'
                        as={Link}
                        to='/Logout'
                      >
                        <FiIcons.FiLogOut /> Cerrar sesión
                      </Button>{' '}
                    </div>
                </SidebarNav>
            </div>
        </IconContext.Provider>
        );
    };

export default SideBar;