import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Stack } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

/* Components */
import Modal from "../../Components/Modal";
import { Loading } from "../../Components/Messages";
import Confirm from "../../Components/Confirm";
import NewDepartament from "./NewDepartament";
import NewPosition from "./NewPosition";

// Assets
import Avatar from "../../Assets/avatar.svg";

/* Icons */
import * as AiIcons from "react-icons/ai";

const moment = require('moment');

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DetailCompany = ({ companyId }) => {
  const [companyData, setCompanyData] = useState(null);

  const [posicionList, setPosicionList] = useState(false);
  const [departamentoList, setDepartamentoList] = useState(false);

  const [departamentoToDelete, setDepartamentoToDelete] = useState(null);
  const [posicionToDelete, setPosicionToDelete] = useState(null);

  const [modalShowConfirmD, setModalShowConfirmD] = useState(false);
  const ModalHandleCloseD = () => {
    getCompanyData();
    setModalShowConfirmD(false);
    getPosicionList();
    getDepartamentoList();
  };
  const handleConfirmD = () => {
    setModalShowConfirmD(false);
    delDepartamentoData();
    getPosicionList();
    getDepartamentoList();
  };

  const [showP, setShowP] = useState(false);
  const [showD, setShowD] = useState(false);

  const handleShow = () => {
    setShowP(true);
    setShowD(true);
  };
  const handleClose = () => {
    getCompanyData();
    getPosicionList();
    getDepartamentoList();
    setShowP(false);
    setShowD(false);
  };

  const [modalShowConfirmP, setModalShowConfirmP] = useState(false);
  const ModalHandleCloseP = () => {
    getCompanyData();
    setModalShowConfirmP(false);
    getPosicionList();
    getDepartamentoList();
  };
  const handleConfirmP = () => {
    setModalShowConfirmP(false);
    delPosicionData();
    getPosicionList();
    getDepartamentoList();
  };

  /* API */
  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }

  const getCompanyData = () => {
    const CompanyD = ApiEndpoint + "/compania/" + companyId;
    axios
      .get(CompanyD, config)
      .then(function (response) {
        console.log(response.data);
        setCompanyData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPosicionList = () => {
    const Posicion = ApiEndpoint + "/posicion/";
    axios.get(Posicion, config).then((response) => {
        setPosicionList(response.data);
    });
  };

  const getDepartamentoList = () => {
    const Departamento = ApiEndpoint + "/departamento/";
    axios.get(Departamento, config).then((response) => {
        setDepartamentoList(response.data);
    });
  };

  const delPosicionData = () => {
    const PosicionDelete = ApiEndpoint + "/posicion/" + posicionToDelete;
    axios
      .delete(PosicionDelete, config)
      .then(function (response) {
        getCompanyData();
        getPosicionList();
        getDepartamentoList();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const delDepartamentoData = () => {
    const DepartamentoDelete = ApiEndpoint + "/departamento/" + departamentoToDelete;
    axios
      .delete(DepartamentoDelete, config)
      .then(function (response) {
        getCompanyData();
        getPosicionList();
        getDepartamentoList();
      })
      .catch(function (error) {
        console.log(error); 
      });
  }

  useEffect(() => {
    getPosicionList();
  }, []);

  useEffect(() => {
    getDepartamentoList();
  }, []);

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
        {companyData !== null ?
        (
          <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 style={{marginTop: "20px"}}>Departamentos</h5>

              <div className='mt-3 d-grid'>
                <div className="CompanyDetails2">
                <ListGroup as="ol">
                {!!departamentoList?.length &&
                  departamentoList.map((departamento) => (
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fs-14">{departamento.nombre}</div>
                        </div>
                        <div style={{fontSize: "14px"}}>
                          <AiIcons.AiOutlineDelete
                            className="IconTable"
                            onClick={() => {
                              setDepartamentoToDelete(departamento.id);
                              setModalShowConfirmD(true);
                            }}
                          />
                        </div>
                      </ListGroup.Item>
                  ))}
                </ListGroup>
                </div>
              </div>
            </Col>
          </Row>

          <div className='mt-0 mb-2 d-grid'>
              <Button
                style={{marginTop: "10px"}}
                variant="outline-success"
                onClick={() => {
                  setShowD(true);
                }}
                >
                  <AiIcons.AiOutlinePlus/>
                  Agregar departamento
              </Button>
          </div>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 style={{marginTop: "20px"}}>Posiciones</h5>

              <div className='mt-3 d-grid'>
                <div className="CompanyDetails2">
                <ListGroup as="ol">
                {!!posicionList?.length &&
                  posicionList.map((posicion) => (
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fs-14">{posicion.nombre}</div>
                        </div>
                        <div style={{fontSize: "14px"}}>
                          <AiIcons.AiOutlineDelete
                            className="IconTable"
                            onClick={() => {
                              setPosicionToDelete(posicion.id);
                              setModalShowConfirmP(true);
                            }}
                          />
                        </div>
                      </ListGroup.Item>
                  ))}
                </ListGroup>
                </div>
              </div>
            </Col>
          </Row>

          <div className='mt-0 d-grid'>
              <Button
                style={{marginTop: "10px"}}
                variant="outline-success"
                onClick={() => {
                  setShowP(true);
                }}
                >
                  <AiIcons.AiOutlinePlus/>
                  Agregar posición
              </Button>
          </div>
          </>
        ) 
        :
        (
          <Loading />
        )
        }
        <Confirm
          show={modalShowConfirmD}
          handleClose={ModalHandleCloseD}
          handleConfirm={handleConfirmD}
          message={"¿Deseas eliminar el departamento de esta empresa?"}
        />

        <Confirm
          show={modalShowConfirmP}
          handleClose={ModalHandleCloseP}
          handleConfirm={handleConfirmP}
          message={"¿Deseas eliminar la posición de esta empresa?"}
        />

        <Modal
          show={showD}
          handleShow={handleShow}
          handleClose={handleClose}
          title={"Agregar departamento"}
        >
          <NewDepartament
            handleClose={handleClose}
            buttonText={"Agregar"}
            idCompany={companyId}
          />
        </Modal>

        <Modal
          show={showP}
          handleShow={handleShow}
          handleClose={handleClose}
          title={"Agregar posición"}
        >
          <NewPosition
            handleClose={handleClose}
            buttonText={"Agregar"}
            idCompany={companyId}
          />
        </Modal>

    </>
  );
};

export default DetailCompany;
