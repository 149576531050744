import React, {useState} from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Link } from "react-router-dom";

// Assets
import Logo from '../../Assets/logo.svg';

/* Icons */
import * as FiIcons from "react-icons/fi";
import * as IoIcons from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';

import * as AiIcons from "react-icons/ai";

// Components
import { SideBarData } from './SideBarData';
import SubMenu from './SubMenu';

/* Bootstrap */
import Button from 'react-bootstrap/Button';

const Nav = styled.div`
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const NavIcon = styled(NavLink)`
    margin-left: 15px;
    font-size: 30px;
    height: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SidebarNav = styled.nav`
    background: #ffffff;
    box-shadow: 0px 0px 60px 0px #0000000f;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 10;
`;

const SidebarWrap = styled.div`
    width: 100%;
    color: #969696;
`;

const TopBar = () => {

    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)

    return (
        <IconContext.Provider value={{ color: '#34583d' }}>
            <Nav className='justify-content-between'>
                <NavIcon to='#'>
                    <AiIcons.AiOutlineMenu onClick={showSidebar} />
                </NavIcon>
                <img src={Logo} alt="logotipo" className='LogotipoTop'/>
            </Nav>
            <SidebarNav sidebar={sidebar}>
                <SidebarWrap>
                    <NavIcon to='#'>
                        <IoIcons.IoCloseOutline onClick={showSidebar} />
                    </NavIcon>
                    {SideBarData.map((item, index) => {
                        return <SubMenu item={item} key={index} />
                    })}
                </SidebarWrap>
                <div className='Logout d-grid'>
                    <Button
                    variant="outline-secondary"
                    size='sm'
                    as={Link}
                    to='/Logout'
                    >
                      <FiIcons.FiLogOut /> Cerrar sesión
                    </Button>{' '}
                </div>
            </SidebarNav>
        </IconContext.Provider>
        );
    };

export default TopBar;