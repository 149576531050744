import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Button, FloatingLabel, Form } from "react-bootstrap";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NewCompany = ({ handleClose, buttonText, idCompany }) => {

  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }
  
  const [selectedCiudad, setSelectedCiudad] = useState(false);
  const [ciudadList, setCiudadList] = useState(false);

  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    nombre: "",
    direccion: "",
    codigo_postal: "",
    sitio_web: "",
    rfc: "",
    ciudad_id: "",
  });

  const getCiudadList = (idCompany) => {
    const Ciudad = ApiEndpoint + "/ciudad/";
    axios.get(Ciudad, config).then((response) => {
        setCiudadList(response.data);
    });
  };

  useEffect(() => {
    getCiudadList(selectedCiudad);
  }, [selectedCiudad]);

  useEffect(() => {
    if(idCompany !== 0)
    {
      let Auth = localStorage.getItem('Auth');
      const URIEmployee = ApiEndpoint + "/compania/" + idCompany;
      let config = { headers:{'Authorization':Auth} }
      axios
        .get(URIEmployee, config)
            .then(function (response){
                setValues(response.data);
            }
        )
    }
  },[idCompany]);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "/compania/";
    let data = {
        nombre: values.nombre,
        direccion: values.direccion,
        codigo_postal: values.codigo_postal,
        sitio_web: values.sitio_web,
        rfc: values.rfc,
        ciudad_id: values.ciudad_id,
      }

      console.log(data);
      if(idCompany !== 0)
      {
        axios
        .put(URICustomer + "/" + idCompany, data, config)
        .then(function (response) {
          console.log(response);
          if (!response.data.error) {
            handleClose();
            return;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      else
      {
        axios
        .post(URICustomer, data, config)
        .then(function (response) {
          console.log(response);
          if (!response.data.error) {
            handleClose();
            return;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
 }
    
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre(s): *" className="mb-3">
          <Form.Control
            type="text"
            name="nombre"
            id="nombre"
            value={values.nombre}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre"
          />
        </FloatingLabel>

        <FloatingLabel label="Ciudad: *" className="mb-3">
            <Form.Select
              id="ciudad_id"
              name="ciudad_id"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedCiudad(`${selectedOption.target.value}`);
              }}
              value={values.ciudad_id}
              required
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!ciudadList?.length &&
                ciudadList.map((ciudad) => (
                  <option value={ciudad.id}>{ciudad.nombre}</option>
                ))}
            </Form.Select>
        </FloatingLabel>

        <FloatingLabel label="Código Postal: *" className="mb-3">
          <Form.Control
            type="text"
            name="codigo_postal"
            id="codigo_postal"
            maxLength="5"
            value={values.codigo_postal}
            required
            pattern="^\d{5}$"
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^\d]+/g, "");
              onChange(event);
            }}
            placeholder="Ingrese el código postal de la empresa"
          />
        </FloatingLabel>

        <FloatingLabel label="Dirección: *" className="mb-3">
          <Form.Control
            type="text"
            name="direccion"
            id="direccion"
            value={values.direccion}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese la dirección del empleado"
          />
        </FloatingLabel>

        <FloatingLabel label="RFC: *" className="mb-3">
          <Form.Control
            type="text"
            name="rfc"
            id="rfc"
            maxLength="13"
            value={values.rfc}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el RFC"
          />
        </FloatingLabel>

        <FloatingLabel label="Sitio web: *" className="mb-3">
          <Form.Control
            type="text"
            name="sitio_web"
            id="sitio_web"
            value={values.sitio_web}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el Sitio web"
          />
        </FloatingLabel>
 
        <Divider />

        <div className='AddEmployee d-grid'>
          <Button variant="success" type="submit" className="align-self-end">
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewCompany;
