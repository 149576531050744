import React from "react";

export const Loading = () => {
    return (
        <>
          <h6 className="MissInfo">Cargando información...</h6>
        </>
      );
};

export const MissInfo = () => {
    return (
        <>
          <h6 className="MissInfo">Aún no hay información de este empleado.</h6>
        </>
      );
};

export default Loading;