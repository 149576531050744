import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//Components
import SideBar from "../Components/Sidebar/SideBar";
import MiniBar from "../Components/Sidebar/MiniBar";
import TopBar from "../Components/Sidebar/TopBar";

const MainLayout = ({ children }) => {
  const [showMini, setShowMini] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let Auth = localStorage.getItem('Auth');
    if(Auth == null)
    {
      navigate("/");
    }
  }, []);
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="g-0 d-lg-none" xs={12} sm={12}>
            <TopBar />
          </Col>

          {showMini
            ?
            (
              <>
              <Col className="g-0 d-none d-lg-block" md={1} lg={1} xl={1}>
                <MiniBar 
                  setShowMini={setShowMini}
                />
              </Col>

              <Col xs={12} sm={12} md={12} lg={11} xl={11}>
                <div className="bodyPage">
                  <Outlet />
                </div>
              </Col>
            </>
            ):(
              <>
              <Col className="g-0 d-none d-lg-block" md={3} lg={3} xl={2}>
                <SideBar
                  setShowMini={setShowMini}
                />
              </Col>

              <Col xs={12} sm={12} md={12} lg={9} xl={10}>
                <div className="bodyPage">
                  <Outlet />
                </div>
              </Col>
            </>
            )
          }
        </Row>
      </Container>
    </>
  );
};

export default MainLayout;