import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Button, FloatingLabel, Form } from "react-bootstrap";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NewPosition = ({ handleClose, buttonText, idCompany }) => {

  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }
  
  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    nombre: "",
  });

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "/posicion/";
    let data = {
        nombre: values.nombre,
    }

    console.log(data);
    axios
    .post(URICustomer, data, config)
    .then(function (response) {
        console.log(response);
        if (!response.data.error) {
        handleClose();
        return;
        } else {
        }
    })
    .catch(function (error) {
        console.log(error);
    });

 }
    
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre(s): *" className="mb-3">
          <Form.Control
            type="text"
            name="nombre"
            id="nombre"
            value={values.nombre}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre"
          />
        </FloatingLabel>

        <Divider />

        <div className='AddEmployee d-grid'>
          <Button variant="success" type="submit" className="align-self-end">
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewPosition;
