/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
import * as AiIcons from "react-icons/ai";

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = "Buscar...",
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <InputGroup className="position-relative">
      <FormControl
        value={value || ""}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <InputGroup.Text className="bg-transparent">
        <AiIcons.AiOutlineSearch
          className="IconTable"
          title="Buscar"
          onClick={() => {}}
        />
      </InputGroup.Text>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
