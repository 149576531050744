import React, {useState} from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

const SidebarLink = styled(NavLink)`
    display: flex;
    color: #767676;
    justify-content: center;
    align-items: center;
    padding: 0px 15px 0px 15px;
    margin: 5px 15px 5px 15px;
    text-align: center;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 22px;
    font-weight: 500;
    border-radius: 0px;

    &:hover{
        transition: 0.15s;
        cursor: poiner;
        background-color: #ebebeb;
        border-radius: 8px;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 5px;
`;

const DropdownLink = styled(NavLink)`
    border-right: 3px solid #2d69c2;
    height: 60px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #353535;
    font-size: 13px;
    font-weight: 500;
    margin-right: -2px;

    &:hover{
        transition: 0.15s;
        cursor: pointer;
        color: #2d69c2;
    }
`;

const SubMenu = ({ item }) => {

const [subnav, setSubnav] = useState(false)

const showSubnav = () => setSubnav(!subnav)

 return (
    <>
    <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div title={item.title}>
            {item.icon}
        </div>
        <div>
            {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
        </div>
    </SidebarLink>
    {subnav && item.subNav.map((item, index) => {
        return(
            <DropdownLink to={item.path} key={index}>
                {item.icon}
            </DropdownLink>
        )
    })}
    </>
 );
};


export default SubMenu;
