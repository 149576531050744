import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../Utilities/ApiEndpoint";

/* Assets */
import Logo from '../Assets/logo.svg';

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Login = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  // State
  const [formData, setFormData] = useState({
  user: "",
  password: "",
  remember: false,
  });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setValidated(true);

    const URI = ApiEndpoint + "/cuenta/acceso";
    axios
      .post(URI, {
        usuario: formData.user,
        contrasena: formData.password,
      })
      .then(function (response) {
        if (!response.data.error) {
          login(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        setShow(true);
      });
  };

  const login = async (data) => {
    localStorage.setItem('Auth', data);
    navigate("/Home");
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Container fluid>
        <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className='g-0'>
                <div className='Cover'>
                    <div className='LoginCover'>
                        <p>Copyright @InHouse 2023 - Todos los derechos reservados</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='Form d-flex align-items-center justify-content-center'>
                    <img src={Logo} alt="logotipo" className='Logotipo'/>
                    <Col  xs={10} sm={10} md={7} lg={8} xl={7}>
                        <div className='FormLogin'>
                            <h2 className='mb-2'>Iniciar sesión</h2>
                            <p>Bienvenido, por favor ingresa tu usuario y contraseña para ingresar.</p>
                            <Form onSubmit={handleSubmit}>
                                <FloatingLabel
                                    label="Usuario:"
                                    className="mb-4"
                                >
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldChange}
                                    value={formData.user}
                                    id="user"
                                    name="user"
                                    placeholder="Usuario:" />
                                </FloatingLabel>

                                <FloatingLabel
                                    label="Contraseña:"
                                    className="mb-4"
                                >
                                <Form.Control
                                    type="password"
                                    onChange={handleFieldChange}
                                    value={formData.password}
                                    id="password"
                                    name="password"
                                    placeholder="Contraseña:" />
                                </FloatingLabel>

                                <Divider />

                                <div className='Send d-grid'>
                                    <Button variant="dark" type="submit">
                                        Ingresar
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </div>
                <ToastContainer className="ToastLogin" position={"bottom-start"}>
                <Toast
                    show={show}
                    onClose={() => setShow(!show)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                    <strong className="me-auto">Datos incorrectos</strong>
                    </Toast.Header>
                    <Toast.Body>Por favor, proporciona tus datos correctamente e intentalo nuevamente</Toast.Body>
                </Toast>
                </ToastContainer>
            </Col>
        </Row>
    </Container>
    );
  };
  export default Login;