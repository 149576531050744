import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Stack } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

/* Components */
import { Loading } from "../../Components/Messages";

// Assets
import Avatar from "../../Assets/avatar.svg";

const moment = require('moment');

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InfoCompany = ({ companyId }) => {
  const [companyData, setCompanyData] = useState(null);

  /* API */
  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }

  const getCompanyData = () => {
    const CompanyD = ApiEndpoint + "/compania/" + companyId;
    axios
      .get(CompanyD, config)
      .then(function (response) {
        console.log(response.data);
        setCompanyData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
        {companyData !== null ?
        (
          <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ms-2 me-auto">
                <div style={{fontSize: "28px", fontWeight: "700", paddingBottom: "5px", paddingTop: "5px", color: "#34593e"}}>{companyData.nombre}</div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="CompanyDetails">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Ciudad:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{companyData.ciudad_id}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Código postal:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{companyData.codigo_postal}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Dirección:
                    </p>
                  </div>
                  <div className="ms-auto">
                  <p style={{ textAlign:"right" }}>
                      <b>{companyData.direccion}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      RFC:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{companyData.rfc}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Sitio web:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{companyData.sitio_web}</b>
                    </p>
                  </div>
                </Stack>{" "}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h5 style={{marginTop: "20px"}}>Empleados</h5>
              <div className='mt-3 d-grid'>
                <div className="CompanyDetails2">
                <ListGroup as="ol">
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Wolfgang Alejandro</div>
                      <span>Operaciones</span>
                    </div>
                    <Badge bg="success"><p>Código: 1101</p></Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Arturo Perea</div>
                      <span>Backend</span>
                    </div>
                    <Badge bg="success"><p>Código: 1102</p></Badge>
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Jose Lara</div>
                      <span>Frontend</span>
                    </div>
                    <Badge bg="success"><p>Código: 1103</p></Badge>
                  </ListGroup.Item>
                </ListGroup>
                </div>
              </div>
            </Col>
          </Row>
          </>
        ) 
        :
        (
          <Loading />
        )
        }
    </>
  );
};

export default InfoCompany;
