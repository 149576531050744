import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

/* Bootstrap */
import { Badge, Button, Stack } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';

/* Icons */
import * as AiIcons from "react-icons/ai";

// Assets
import Avatar from "../../Assets/avatar.svg";

/* Tables */
import AdvanceTableFooter from "../../Components/Table/AdvanceTableFooter";
import AdvanceTablePagination from "../../Components/Table/AdvanceTablePagination";
import AdvanceTable from "../../Components/Table/AdvanceTable";
import AdvanceTableWrapper from "../../Components/Table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../Components/Table/AdvanceTableSearchBox";

/* Components */
import Modal from "../../Components/Modal";
import NewCompany from "./NewCompany";
import InfoCompany from "./InfoCompany";
import DetailCompany from "./DetailCompany";
import Confirm from "../../Components/Confirm";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Companies = () => {
  const [companyData, setCompanyData] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [companyToDelete, setCompanyToDelete] = useState(null);

  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const ModalHandleClose = () => setModalShowConfirm(false);
  const handleConfirm = () => {
    setModalShowConfirm(false);
    delCompanyData();
  };

  const [show, setShow] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const handleClose = () => {
    getCompanyData();
    setShow(false);
    setShowInfoModal(false);
    setShowDetailModal(false);
  };
  const handleShow = () => {
    setShow(true)
    setShowInfoModal(true);
    setShowDetailModal(true);
  };

  /* API */
  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }

  const getCompanyData = () => {
    const CompanyD = ApiEndpoint + "/compania/";
    axios
      .get(CompanyD, config)
      .then(function (response) {
        setCompanyData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const delCompanyData = () => {
    const CompanyDelete = ApiEndpoint + "/compania/" + companyToDelete;
    axios
      .delete(CompanyDelete, config)
      .then(function (response) {
        getCompanyData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getCompanyData();
  }, []);

  const columns = [
    {
        accessor: "nombre",
        Header: "Empresa",
        cellProps: {
          className: "CompanyNames",
        },
    },
    {
      accessor: "rfc",
      Header: "RFC",
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idCompany = rowData.row.original.id;
        return (
          <>
            <AiIcons.AiOutlineMenu
              className="IconTable"
              onClick={() => {
                setCompanyId(idCompany);
                setShowInfoModal(true);
              }}
            />{' | '}
            <AiIcons.AiOutlineSetting
              className="IconTable"
              onClick={() => {
                setCompanyId(idCompany);
                setShowDetailModal(true);
              }}
            />{' | '}
            <AiIcons.AiOutlineEdit
              className="IconTable"
              onClick={() => {
                setCompanyId(idCompany);
                setShow(true);
              }}
            />{' | '}
            <AiIcons.AiOutlineDelete
              className="IconTable"
              onClick={() => {
                setCompanyToDelete(idCompany);
                setModalShowConfirm(true);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Empresas</h4>
          <div className="ms-auto">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => {
                setCompanyId(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        <Divider />
        
        {companyData && (
          <AdvanceTableWrapper
            columns={columns}
            data={companyData}
            sortable
            pagination
            perPage={10}
          >
            <Stack direction="horizontal" gap={2} className="wrappTitle">
              <AdvanceTableSearchBox
                table
              />
              <div className="ms-auto UpdateBtn">
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    getCompanyData();
                  }}
                >
                  <AiIcons.AiOutlineSync /> Actualizar
                </Button>{' '}
              </div>
            </Stack>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <Stack direction="horizontal" gap={2} className="wrappTitle mt-4">
              <AdvanceTableFooter
                  rowCount={companyData.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                  pageSize={10}
                />
              <div className="ms-auto">
                <AdvanceTablePagination table />
              </div>
            </Stack>
          </AdvanceTableWrapper>
        )}
      </div>
      <Modal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={companyId > 0 ? "Editar empresa" : "Agregar empresa"}
      >
        <NewCompany
          handleClose={handleClose}
          buttonText={companyId > 0 ? "Editar" : "Agregar"}
          idCompany={companyId}
          />
      </Modal>

      <Modal
        show={showInfoModal}
        handleShow={handleShow}
        handleClose={handleClose}
        title={"Información completa"}
      >
        <InfoCompany
          companyId={companyId}
        />
      </Modal>

      <Modal
        show={showDetailModal}
        handleShow={handleShow}
        handleClose={handleClose}
        title={"Departamentos y posiciones"}
      >
        <DetailCompany
          companyId={companyId}
        />
      </Modal>

      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"¿Deseas eliminar el registro de esta empresa?"}
      />
    </>
  );
};

export default Companies;
