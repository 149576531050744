import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./Home";
import Login from "./Login";
import Logout from "./Logout";
import NotFound from "./NotFound";
import Employees from "./Employees/Employees";
import Companies from "./Companies/Companies";
import Schedules from "./Schedules/Schedules";

import MainLayout from "../Layouts/MainLayout";

const Router = () => (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route element={ <MainLayout />} >
        <Route path="/Home" element={<Home />} />
        <Route path="/Employees" element={<Employees />} />
        <Route path="/Companies" element={<Companies />} />
        <Route path="/Schedules" element={<Schedules />} />

        <Route path="/Logout" element={<Logout />} />
      </Route>
  
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  
  export default Router;