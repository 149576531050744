import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Button, FloatingLabel, Form } from "react-bootstrap";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const NewEmployee = ({ handleClose, buttonText, idEmployee }) => {

  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }
  
  
  const [selectedPosicion, setSelectedPosicion] = useState(false);
  const [posicionList, setPosicionList] = useState(false);

  const [selectedDepartamento, setSelectedDepartamento] = useState(false);
  const [departamentoList, setDepartamentoList] = useState(false);

  const [selectedCivil, setSelectedCivil] = useState(false);
  const [civilList, setCivilList] = useState(false);

  const [selectedCompania, setSelectedCompania] = useState(false);
  const [companiaList, setCompaniaList] = useState(false);

  const [validated, setValidated] = useState(false);
  const [values, setValues] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_de_nacimiento: "",
    genero: "",
    tipo_sangre: "",
    estado_civil_id: "",
    curp: "",
    direccion: "",
    rfc: "",
    compania_id: "",
    departamento_id: "",
    posicion_id: "",
    fecha_de_inicio: "",
  });

  const getPosicionList = (idEmployee) => {
    const Posicion = ApiEndpoint + "/posicion/";
    axios.get(Posicion, config).then((response) => {
        setPosicionList(response.data);
    });
  };

  const getDepartamentoList = (idEmployee) => {
    const Departamento = ApiEndpoint + "/departamento/";
    axios.get(Departamento, config).then((response) => {
        setDepartamentoList(response.data);
    });
  };

  const getCivilList = (idEmployee) => {
    const CivilStatus = ApiEndpoint + "/estado-civil/";
    axios.get(CivilStatus, config).then((response) => {
        setCivilList(response.data);
    });
  };

  const getCompaniaList = (idEmployee) => {
    const Compania = ApiEndpoint + "/compania/";
    axios.get(Compania, config).then((response) => {
        setCompaniaList(response.data);
    });
  };

  useEffect(() => {
    getPosicionList(selectedPosicion);
  }, [selectedPosicion]);

  useEffect(() => {
    getDepartamentoList(selectedDepartamento);
  }, [selectedDepartamento]);

  useEffect(() => {
    getCivilList(selectedCivil);
  }, [selectedCivil]);

  useEffect(() => {
    getCompaniaList(selectedCompania);
  }, [selectedCompania]);


  useEffect(() => {
    if(idEmployee !== 0)
    {
      let Auth = localStorage.getItem('Auth');
      const URIEmployee = ApiEndpoint + "/empleado/" + idEmployee;
      let config = { headers:{'Authorization':Auth} }
      axios
        .get(URIEmployee, config)
            .then(function (response){
                setValues(response.data);
            }
        )
    }
  },[idEmployee]);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    const URICustomer = ApiEndpoint + "/empleado/";
    let data = {
        nombre: values.nombre,
        apellido_paterno: values.apellido_paterno,
        apellido_materno: values.apellido_materno,
        fecha_de_nacimiento: values.fecha_de_nacimiento,
        genero: values.genero,
        tipo_sangre: values.tipo_sangre,
        estado_civil_id: values.estado_civil_id,
        curp: values.curp,
        direccion: values.direccion,
        rfc: values.rfc,
        compania_id: values.compania_id,
        departamento_id: values.departamento_id,
        posicion_id: values.posicion_id,
        fecha_de_inicio: values.fecha_de_inicio,
      }

      console.log(data);
      if(idEmployee !== 0)
      {
        axios
        .put(URICustomer + "/" + idEmployee, data, config)
        .then(function (response) {
          console.log(response);
          if (!response.data.error) {
            handleClose();
            return;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      else
      {
        axios
        .post(URICustomer, data, config)
        .then(function (response) {
          console.log(response);
          if (!response.data.error) {
            handleClose();
            return;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
 }
    
  const onChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit1}>
        <FloatingLabel label="Nombre(s): *" className="mb-3">
          <Form.Control
            type="text"
            name="nombre"
            id="nombre"
            value={values.nombre}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el nombre"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido paterno: *" className="mb-3">
          <Form.Control
            type="text"
            name="apellido_paterno"
            id="apellido_paterno"
            value={values.apellido_paterno}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el apellido paterno"
          />
        </FloatingLabel>
        <FloatingLabel label="Apellido materno: *" className="mb-3">
          <Form.Control
            type="text"
            name="apellido_materno"
            id="apellido_materno"
            value={values.apellido_materno}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el apellido materno"
          />
        </FloatingLabel>
        <FloatingLabel label="Fecha de nacimiento: *" className="mb-3">
        <Form.Control
            type="date"
            name="fecha_de_nacimiento"
            id="fecha_de_nacimiento"
            required
            onChange={(event) => {
              onChange(event);
            }}
            value={values.fecha_de_nacimiento}
            placeholder="Ingrese la fecha de nacimiento"
        />
        </FloatingLabel>
        <FloatingLabel label="Genero *" className="mb-3">
        <Form.Select
            id="genero"
            name="genero"
            onChange={(event) => {
              onChange(event);
            }}
            value={values.genero}
            required
        >
            <option value="">Seleccione una opcion de la lista</option>
            <option value="masculino">Masculino</option>
            <option value="femenino">Femenino</option>
        </Form.Select>
        </FloatingLabel>

        <FloatingLabel label="Tipo de sangre *" className="mb-3">
        <Form.Select
            id="tipo_sangre"
            name="tipo_sangre"
            onChange={(event) => {
              onChange(event);
            }}
            value={values.tipo_sangre}
            required
        >
            <option value="">Seleccione una opcion de la lista</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
        </Form.Select>
        </FloatingLabel>

        <FloatingLabel label="Estado civil: *" className="mb-3">
            <Form.Select
              id="estado_civil_id"
              name="estado_civil_id"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedCivil(`${selectedOption.target.value}`);
              }}
              value={values.estado_civil_id}
              required
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!civilList?.length &&
                civilList.map((civil) => (
                  <option value={civil.id}>{civil.nombre}</option>
                ))}
            </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="CURP: *" className="mb-3">
          <Form.Control
            type="text"
            name="curp"
            id="curp"
            value={values.curp}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el CURP"
          />
        </FloatingLabel>
        <FloatingLabel label="Dirección: *" className="mb-3">
          <Form.Control
            type="text"
            name="direccion"
            id="direccion"
            value={values.direccion}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese la dirección del empleado"
          />
        </FloatingLabel>

        <Divider />

        <div class="offcanvas-title h5" style={{ marginBottom: "20px" }}>Datos empresariales</div>

        <FloatingLabel label="RFC: *" className="mb-3">
          <Form.Control
            type="text"
            name="rfc"
            id="rfc"
            value={values.rfc}
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese el RFC"
          />
        </FloatingLabel>
        <FloatingLabel label="Empresa: *" className="mb-3">
            <Form.Select
              id="compania_id"
              name="compania_id"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedCompania(`${selectedOption.target.value}`);
              }}
              value={values.compania_id}
              required
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!companiaList?.length &&
                companiaList.map((compania) => (
                  <option value={compania.id}>{compania.nombre}</option>
                ))}
            </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Departamento: *" className="mb-3">
            <Form.Select
              id="departamento_id"
              name="departamento_id"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedDepartamento(`${selectedOption.target.value}`);
              }}
              value={values.departamento_id}
              required
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!departamentoList?.length &&
                departamentoList.map((departamento) => (
                  <option value={departamento.id}>{departamento.nombre}</option>
                ))}
            </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Posición: *" className="mb-3">
            <Form.Select
              id="posicion_id"
              name="posicion_id"
              onChange={(selectedOption) => {
                onChange(selectedOption);
                setSelectedPosicion(`${selectedOption.target.value}`);
              }}
              value={values.posicion_id}
              required
            >
              <option value="">Seleccione una opcion de la lista</option>
              {!!posicionList?.length &&
                posicionList.map((posicion) => (
                  <option value={posicion.id}>{posicion.nombre}</option>
                ))}
            </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Fecha de inicio: *" className="mb-3">
        <Form.Control
            type="date"
            name="fecha_de_inicio"
            id="fecha_de_inicio"
            required
            onChange={(event) => {
              onChange(event);
            }}
            placeholder="Ingrese la fecha de inicio"
            value={values.fecha_de_inicio}
        />
        </FloatingLabel>
        
        <Divider />

        <div className='AddEmployee d-grid'>
          <Button variant="success" type="submit" className="align-self-end">
            {buttonText}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default NewEmployee;
