import React, { useState, useEffect } from "react";
import styled from "styled-components";

/* Bootstrap */
import { Stack } from "react-bootstrap";
import { Col, Row, Image } from "react-bootstrap";
import Button from 'react-bootstrap/Button';

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

/* Components */
import { Loading } from "../../Components/Messages";

// Assets
import Avatar from "../../Assets/avatar.svg";

/* Icons */
import * as AiIcons from "react-icons/ai";

const moment = require('moment');

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const InfoEmployee = ({ employeeId }) => {
  const [employeeData, setEmployeeData] = useState(null);

  /* API */
  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }

  const getEmployeeData = () => {
    const EmployeeD = ApiEndpoint + "/empleado/" + employeeId;
    axios
      .get(EmployeeD, config)
      .then(function (response) {
        console.log(response.data);
        setEmployeeData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <>
        {employeeData !== null ?
        (
          <>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="ms-2 me-auto">
                <Image
                  src={Avatar}
                  className="PicInfo"
                  roundedCircle
                />
                <div style={{fontSize: "20px", fontWeight: "700", paddingTop: "5px", color: "#34593e"}}>{employeeData.nombre} {employeeData.apellido_paterno} {employeeData.apellido_materno}</div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="EmployeeDetails">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Fecha de nacimiento:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{moment(employeeData.fecha_de_nacimiento).format("DD/MM/yyyy")}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Género:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.genero}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Tipo de sangre:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.tipo_sangre}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Estado civil:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.estado_civil.nombre}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      CURP:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.curp}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Dirección:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p style={{ textAlign:"right" }}>
                      <b>{employeeData.direccion}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Divider />
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Código de empleado:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.codigo_empleado}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      RFC:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.rfc}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Empresa:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.companium.nombre}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Departamento:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.departamento.nombre}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Posición:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{employeeData.posicion.nombre}</b>
                    </p>
                  </div>
                </Stack>{" "}
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <p>
                      Fecha de inicio:
                    </p>
                  </div>
                  <div className="ms-auto">
                    <p>
                      <b>{moment(employeeData.fecha_de_inicio).format("DD/MM/yyyy")}</b>
                    </p>
                  </div>
                </Stack>{" "}
              </div>
            </Col>
          </Row>
          <div className='mt-3 d-grid'>

              <Button style={{marginTop: "10px"}} variant="outline-success"> <AiIcons.AiOutlinePlus/> Información Formativa</Button>
              <Button style={{marginTop: "10px"}} variant="outline-success"> <AiIcons.AiOutlinePlus/> Información Laboral</Button>
              <Button style={{marginTop: "10px"}} variant="outline-success"> <AiIcons.AiOutlinePlus/> Información Bancaria</Button>
              <Button style={{marginTop: "10px"}} variant="outline-success"> <AiIcons.AiOutlinePlus/> Información Médica</Button>

          </div>
          </>
        ) 
        :
        (
          <Loading />
        )
        }
    </>
  );
};

export default InfoEmployee;
