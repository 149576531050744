/* eslint-disable react/prop-types */
import classNames from "classnames";
import React from "react";
import { Pagination } from "react-bootstrap";

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage,
}) => {
  return (
    <div className="Pagination">
      <Pagination size="sm">
        <Pagination.First
          onClick={() => gotoPage(0)}
          className={classNames({ disabled: pageIndex == 0 })}
        />
        <Pagination.Prev
          onClick={() => previousPage()}
          className={classNames({ disabled: !canPreviousPage })}
        />

        {Array.from(Array(pageCount).keys())
          .filter((page, index) => {
            return (
              //(page <= pageIndex + 3 && page >= pageIndex) 
              //||(pageCount == pageIndex + 1 && page >= pageCount - 4)
              //||(pageCount - 3 >= pageIndex + 1 && page > pageCount - 4)
              (page <= pageIndex + 2 && page >= pageIndex)
              ||(page <= pageIndex && page >= pageIndex - 2)
            );
          })
          .map((page, index) => (
            <li
              key={page}
              className={classNames({
                active: pageIndex === page,
              })}
            >
              <Pagination.Item
                className={classNames("page", {
                  "me-0": index + 1 !== pageCount,
                })}
                onClick={() => gotoPage(page)}
              >
                {" "}
                {page + 1}
              </Pagination.Item>
            </li>
          ))
        }
        
        <Pagination.Next
          onClick={() => nextPage()}
          className={classNames({ disabled: !canNextPage })}
        />
        <Pagination.Last
          onClick={() => gotoPage(pageCount - 1)}
          className={classNames({ disabled: !canNextPage })}
        />
      </Pagination>
    </div>
  );
};

export default AdvanceTablePagination;
