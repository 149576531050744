import React, { useState } from "react";
import styled from "styled-components";

// Bootstrap
import Button from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import { Form } from "react-bootstrap";

//Components
import Modal from "../Components/Modal";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);

  return (
  <>
    <div className="">
        <Stack direction="horizontal">
          <h4>Buenos días.</h4>
          <div className="ms-auto">
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Busca algo..."
                className="me-2 searchBar"
                aria-label="Search"
              />
              <Button
                variant="outline-success"
                size="sm"
              >Buscar</Button>
            </Form>
          </div>
        </Stack>
    </div>

    <Divider />

    <Modal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title="Asd"
    >
    </Modal>
  </>
  );
};

export default Home;
