import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";

/* Bootstrap */
import { Col, Row, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

/* API */
import axios from "axios";
import { ApiEndpoint, StaticPoint } from "../../Utilities/ApiEndpoint";

// Assets
// import Avatar from "../../Assets/avatar.svg";

const fileTypes = ["JPG", "PNG", "GIF"];

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PicEmployee = ({ employeeId, employeeName, Avatar, handleClose }) => {

  const [currentUid, setCurrentUid] = useState(null);
  const [validated, setValidated] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [file, setFile] = useState(null);
  const [isWorking, setIsWorking] = useState(false);
  const [started, setStarted] = useState(false);
  const [data, setData] = useState(null);
  const [itemsProcessed, setItemsProcessed] = useState(null);
  const [invalidItemsProcessed, setInvalidItemsProcessed] = useState(null);
  const [invalidItems, setInvalidItems] = useState(null);
  const [dataToExport, setDataToExport] = useState(null);

  const [avatar, setAvatar] = useState(Avatar);
  const [fileName, setFileName] = useState(null);

  const [values, setValues] = useState({
    name: "",
  });

  const clearData = () => {
    setDataToExport(null);
    setData(null);
    setStarted(false);
    setIsWorking(false);
    setInvalidItems(null);
    setInvalidItemsProcessed(null);
  };

  const refreshData = () => {
    getTmpData();
  };

  const getTmpData = () => {
    const URI = ApiEndpoint + "campaigns/reporttmpdatabase/" + currentUid;
    axios.get(URI).then((response) => {
      setStarted(false);
      setData(response.data[0]);
    });
  };

  const getTmpDataError = () => {
    const URI =
      ApiEndpoint + "campaigns/getPartnerDatabasesTmpError/" + currentUid;
    axios.get(URI).then((response) => {
      setDataToExport(response.data);
    });
  };

  const handleChange = (file) => {
    console.log(file);
    setFile(file);
  };

  let Auth = localStorage.getItem('Auth');


  const handleFileUpload = (file) => {
    if(file) {
      setAttachment(file);

      const formData = new FormData();
      formData.append("name", "avatar");
      formData.append("filename", file.originalname);
      formData.append("avatar", file);
      setFileName(file.originalname);

      const URISaveFile = ApiEndpoint + "/empleado/avatar/" + employeeId;
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: URISaveFile,
        headers: { "Content-Type": "multipart/form-data", "Authorization": Auth},
        data: formData,
      };

      axios
        .request(config)
        .then((res) => {
          setAvatar(StaticPoint + "/avatar/" + res.data.data.name);
          handleClose();
        })
        .catch((err) => {
          alert("Error al subir la imagen");
        }
        );
    }
  };

  const processFile = (dataitems, fieldsFromFile) => {
    var data = [],
      failedData = [],
      headers = [];
    var invalidCount = 0;
    dataitems.map((item) => {
      var msisdn = item[Object.keys(item)[0]];
      if (
        msisdn &&
        /^\d+$/.test(msisdn) &&
        (msisdn.length === 10 ||
          (msisdn.length === 12 && msisdn.startsWith("52")))
      ) {
        data.push({
          msisdn: item[Object.keys(item)[0]],
          details: item,
        });
      } else {
        failedData.push({
          msisdn: item[Object.keys(item)[0]],
          details: item,
        });
        invalidCount++;
      }
    });
    console.log("setItemsProcessed");
    console.log(data);
    setInvalidItems(invalidCount);
    setItemsProcessed(data);
    setInvalidItemsProcessed(failedData);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="text-center">
          <Image
            src={avatar}
            className="PicEdit"
            roundedCircle
          />
          </div>
          <div className="UploadPic">
          <p> Agregar o cambia la foto de <b>{employeeName}</b></p>
            <Form noValidate validated={validated}>
              <FileUploader
                handleChange={handleFileUpload}
                name="file"
                dropMessageStyle={{backgroundColor: "#198754", color: "white"}}
                label="Carga o suelta una imagen aquí"
                types={fileTypes} />
            </Form>
          </div>
          <Alert key={"success"} variant={"success"} className="Notice">
          <b>Nota:</b> Los formatos de imagen aceptados para subir fotografías
          de los empleados son: <b>JPG, PNG o GIF</b>.
        </Alert>
        </Col>
      </Row>
    </>
  );
}

export default PicEmployee;