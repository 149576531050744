import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* API */
import axios from "axios";
import { ApiEndpoint, StaticPoint } from "../../Utilities/ApiEndpoint";

/* Bootstrap */
import { Badge, Button, Stack } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';

/* Icons */
import * as AiIcons from "react-icons/ai";

// Assets
import Avatar from "../../Assets/avatar.svg";

/* Tables */
import AdvanceTableFooter from "../../Components/Table/AdvanceTableFooter";
import AdvanceTablePagination from "../../Components/Table/AdvanceTablePagination";
import AdvanceTable from "../../Components/Table/AdvanceTable";
import AdvanceTableWrapper from "../../Components/Table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../Components/Table/AdvanceTableSearchBox";

/* Components */
import Modal from "../../Components/Modal";
import NewEmployee from "./NewEmployee";
import InfoEmployee from "./InfoEmployee";
import PicEmployee from "./PicEmployee";
import Confirm from "../../Components/Confirm";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Employees = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);

  const [employeeName, setEmployeeName] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const ModalHandleClose = () => setModalShowConfirm(false);
  const handleConfirm = () => {
    setModalShowConfirm(false);
    delEmployeeData();
  };

  const [show, setShow] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showPicModal, setShowPicModal] = useState(false);

  const handleClose = () => {
    getEmployeeData();
    setShow(false);
    setShowInfoModal(false);
    setShowPicModal(false);
  };
  const handleShow = () => {
    setShow(true)
    setShowInfoModal(true);
    setShowPicModal(true);
  };

  /* API */
  let Auth = localStorage.getItem('Auth');
  let config = { headers:{'Authorization':Auth} }

  const getEmployeeData = () => {
    const EmployeeD = ApiEndpoint + "/empleado/";
    axios
      .get(EmployeeD, config)
      .then(function (response) {
        setEmployeeData(response.data.rows);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const delEmployeeData = () => {
    const EmployeeDelete = ApiEndpoint + "/empleado/" + employeeToDelete;
    axios
      .delete(EmployeeDelete, config)
      .then(function (response) {
        getEmployeeData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getEmployeeData();
  }, []);

  const columns = [
    {
      accessor: "foto",
      Header: "",
      cellProps: {
        className: "table-picture",
      },
      Cell: (rowData) => {
        let foto = rowData.row.original.foto;
        let idEmployee = rowData.row.original.id;
        let nameEmployee = rowData.row.original.nombre;
        return (
          <>
            {foto ? (
              <Figure>
                <Figure.Image
                  onClick={() => {
                    setEmployeeId(idEmployee);
                    setEmployeeName(nameEmployee);
                    setAvatar(StaticPoint + "/avatar/" + foto);
                    setShowPicModal(true);
                    
                  }}
                  width={30}
                  height={30}
                  src={StaticPoint + "/avatar/" + foto}
                />
              </Figure>
            ) : (
              <Figure>
                <Figure.Image
                  onClick={() => {
                    setEmployeeId(idEmployee);
                    setEmployeeName(nameEmployee);
                    setAvatar(Avatar);
                    setShowPicModal(true);
                  }}
                  width={30}
                  height={30}
                  src={Avatar}
                />
              </Figure>
            )}
          </>
        );
      },
    },
    {
      accessor: "nombre",
      Header: "Nombre",
      Cell: (rowData) => {
        let nombre = rowData.row.original.nombre;
        let apellidoP = rowData.row.original.apellido_paterno;
        let apellidoM = rowData.row.original.apellido_materno;
        return (
          <>
            {nombre}{' '}{apellidoP}{' '}{apellidoM} 
          </>
        );
      },
    },
    {
      accessor: "rfc",
      Header: "RFC",
    },
    {
      accessor: "huella",
      Header: "Huella",
      Cell: (rowData) => {
        let active = rowData.row.original.huella;
        return (
          <>
            {active ? (
              <Badge bg="success">Registrada</Badge>
            ) : (
              <Badge bg="secondary">Sin registrar</Badge>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "Acciones",
      disableSortBy: true,
      headerProps: {
        className: "text-center",
      },
      cellProps: {
        className: "text-center",
      },
      Cell: (rowData) => {
        let idEmployee = rowData.row.original.id;
        return (
          <>
            <AiIcons.AiOutlineMenu
              className="IconTable"
              onClick={() => {
                setEmployeeId(idEmployee);
                setShowInfoModal(true);
              }}
            />{' | '}
            <AiIcons.AiOutlineEdit
              className="IconTable"
              onClick={() => {
                setEmployeeId(idEmployee);
                setShow(true);
              }}
            />{' | '}
            <AiIcons.AiOutlineDelete
              className="IconTable"
              onClick={() => {
                setEmployeeToDelete(idEmployee);
                setModalShowConfirm(true);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Empleados</h4>
          <div className="ms-auto">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => {
                setEmployeeId(0);
                setShow(true);
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        <Divider />
        
        {employeeData && (
          <AdvanceTableWrapper
            columns={columns}
            data={employeeData}
            sortable
            pagination
            perPage={10}
          >
            <Stack direction="horizontal" gap={2} className="wrappTitle">
              <AdvanceTableSearchBox
                table
              />
              <div className="ms-auto UpdateBtn">
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    getEmployeeData();
                  }}
                >
                  <AiIcons.AiOutlineSync /> Actualizar
                </Button>{' '}
              </div>
            </Stack>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
            <Stack direction="horizontal" gap={2} className="wrappTitle mt-4">
              <AdvanceTableFooter
                  rowCount={employeeData.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                  pageSize={10}
                />
              <div className="ms-auto">
                <AdvanceTablePagination table />
              </div>
            </Stack>
          </AdvanceTableWrapper>
        )}
      </div>
      <Modal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        title={employeeId > 0 ? "Editar empleado" : "Agregar empleado"}
      >
        <NewEmployee
          handleClose={handleClose}
          buttonText={employeeId > 0 ? "Editar" : "Agregar"}
          idEmployee={employeeId}
          />
      </Modal>

      <Modal
        show={showPicModal}
        handleShow={handleShow}
        handleClose={handleClose}
        title={"Agregar Foto"}
      >
        <PicEmployee
          handleClose={handleClose}
          employeeId={employeeId}
          employeeName={employeeName}
          Avatar={avatar}
        />
      </Modal>

      <Modal
        show={showInfoModal}
        handleShow={handleShow}
        handleClose={handleClose}
        title={"Información completa"}
      >
        <InfoEmployee
          employeeId={employeeId}
        />
      </Modal>

      <Confirm
        show={modalShowConfirm}
        handleClose={ModalHandleClose}
        handleConfirm={handleConfirm}
        message={"¿Deseas eliminar este registro?"}
      />
    </>
  );
};

export default Employees;
