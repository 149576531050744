import React from "react";

// Assets
import * as FiIcons from "react-icons/fi";

export const SideBarData = [
  {
    title: "Inicio",
    path: "/Home",
    icon: <FiIcons.FiHome />,
  },
  {
    title: "Empleados",
    path: "/Employees",
    icon: <FiIcons.FiUsers />,
  },
  {
    title: "Empresas",
    path: "/Companies",
    icon: <FiIcons.FiBriefcase />,
  },
  {
    title: "Horarios",
    path: "/Schedules",
    icon: <FiIcons.FiClock />,
  },
  /* {
    title: "Campañas",
    path: "/Campaigns/Campaigns",
    icon: <RiIcons.RiLineChartLine />,
  },
  {
    title: "Administracion",
    icon: <FiIcons.FiSliders />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Clientes",
        path: "/administration/Customers",
        icon: <FiIcons.FiUserCheck />,
      },
      {
        title: "Marcaciones",
        path: "/administration/shortcodes",
        icon: <FiIcons.FiPhoneCall />,
      },
    ],
  },
  {
    title: "Usuarios",
    path: "/users",
    icon: <FiIcons.FiUsers />,
  }, */
  
];
