import React, { useEffect, useState } from "react";
import styled from "styled-components";

/* API */
import axios from "axios";
import { ApiEndpoint } from "../../Utilities/ApiEndpoint";

/* Bootstrap */
import { Badge, Button, Stack } from "react-bootstrap";
import Figure from 'react-bootstrap/Figure';

/* Icons */
import * as AiIcons from "react-icons/ai";

// Assets
import Avatar from "../../Assets/avatar.svg";

/* Tables */
import AdvanceTableFooter from "../../Components/Table/AdvanceTableFooter";
import AdvanceTablePagination from "../../Components/Table/AdvanceTablePagination";
import AdvanceTable from "../../Components/Table/AdvanceTable";
import AdvanceTableWrapper from "../../Components/Table/AdvanceTableWrapper";
import AdvanceTableSearchBox from "../../Components/Table/AdvanceTableSearchBox";

/* Components */
import Modal from "../../Components/Modal";
import Confirm from "../../Components/Confirm";

const Divider = styled.div`
  background: #dfdfdf;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Schedules = () => {
  
  return (
    <>
      <div>
        <Stack direction="horizontal" gap={2} className="wrappTitle">
          <h4>Horarios</h4>
          <div className="ms-auto">
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => {
              }}
            >
              <AiIcons.AiOutlinePlus /> Agregar
            </Button>{" "}
          </div>
        </Stack>

        <Divider />
      </div>
      
    </>
  );
};

export default Schedules;
